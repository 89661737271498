import { useEffect, useState } from "react";
import { Box, Typography, Link } from "@mui/material";

import theme from "theme";
import Button from "components/Atoms/Button";
import { useTranslation } from "next-i18next";
import { Trans } from "react-i18next";

const LinkToCookiesPolicy = ({ href = "/polityka-cookies", label = "" }) => (
  <Link color="secondary" underline="hover" href={href} target="_blank">
    {label}
  </Link>
);

const LinkToPrivacyPolicy = ({
  href = "/polityka-prywatnosci",
  label = "",
}) => (
  <Link color="secondary" underline="hover" href={href} target="_blank">
    {label}
  </Link>
);

const CookieInfo = () => {
  const [display, setDisplay] = useState(false);

  const { t } = useTranslation("");

  function getCookie(name) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [k, v] = el.split("=");
      cookie[k.trim()] = v;
    });
    return cookie[name];
  }

  function setCookie(name, value, days = 365) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  useEffect(() => {
    const cookieInfoInCookie = getCookie("cookie_accept");

    if (!cookieInfoInCookie) {
      setDisplay(true);
    }
  }, []);

  if (!display) return null;
  return (
    <Box
      zIndex={100}
      position="fixed"
      bottom={0}
      left={0}
      width={"100%"}
      bgcolor="white"
      borderTop="1px solid rgba(66, 68, 90, 0.2)"
      py={4}
      px={8}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          px: 4,
        },
      }}
    >
      <Typography
        sx={{
          [theme.breakpoints.down("md")]: {
            fontSize: "0.75em",
          },
        }}
      >
        <Trans
          i18nKey="cookieConsent"
          components={{
            1: <LinkToPrivacyPolicy label={t("privacyPolicy")} />,
            3: <LinkToCookiesPolicy label={t("cookiesPolicy")} />,
          }}
        />
      </Typography>

      <Button
        color="primary"
        sx={{
          width: "max-content !important",
          marginLeft: 4,
          [theme.breakpoints.down("md")]: {
            marginTop: 4,
          },
        }}
        onClick={() => {
          setCookie("cookie_accept", true);
          setDisplay(false);
        }}
      >
        {t("OK, close")}
      </Button>
    </Box>
  );
};

export default CookieInfo;
