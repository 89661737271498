import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Close, FavoriteBorder } from "@mui/icons-material";
import { useEffect, useState } from "react";
import PropertiesService from "services/PropertiesService";

import { isArray } from "utils/functions";
import Item from "./components/Item";
import useFavorites from "hooks/useFavorites";
import { useTranslation } from "next-i18next";
import { useLockedScroll } from "hooks/useLockedScroll";

const FavoritesModal = ({ open, handleClose }) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const { t } = useTranslation();

  const { getAllFavorites, removeFromFavorites } = useFavorites();

  useLockedScroll(open);

  useEffect(() => {
    if (open && getAllFavorites?.length > 0) {
      setLoader(true);

      setTimeout(() => {
        fetchData(getAllFavorites);
      }, 300);
    } else {
      setData([]);
    }
  }, [getAllFavorites, open]);

  const fetchData = async (favorites) => {
    setLoader(true);

    if (!isArray(favorites) || favorites?.length === 0) return;
    try {
      const response = await PropertiesService.getProperties({
        uuid: favorites,
        limit: 99,
      });

      if (isArray(response?.data?.data)) {
        setData(response?.data?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }

    setLoader(false);
  };

  const handleRemove = async (uuid) => {
    await removeFromFavorites(uuid);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={"Favorites"}
      disableScrollLock
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width={{ xs: "90%", md: "60%" }}
        bgcolor="background.paper"
        boxShadow={24}
        borderRadius={2}
        sx={{
          transform: "translate(-50%, -50%)",
        }}
        maxHeight="80vh"
        overflow="auto"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl={4}
          pr={2}
          py={2}
        >
          <Typography variant="h6" component="h2">
            {t("Favorites")}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box height="100%" sx={{ mt: 2 }}>
          {loader ? (
            <Box display="flex" justifyContent="center" p={8}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Box height="100%">
              {data?.length > 0 ? (
                <Box height="100%" p={4}>
                  {data?.map((el) => (
                    <Item
                      key={el.uuid}
                      closeModal={handleClose}
                      handleRemove={handleRemove}
                      {...el}
                    />
                  ))}
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  p={8}
                >
                  <FavoriteBorder fontSize="large" />
                  <Typography fontSize="1.5rem" p={4}>
                    {t("The favorites list is empty")}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default FavoritesModal;
