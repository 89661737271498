import { useEffect, useState, useRef } from "react";
import { Alert } from "@mui/material";
import theme from "theme";

function useSnackbarBase() {
  const [isActive, setIsActive] = useState(false);
  const [component, setComponent] = useState(null);
  const timerRef = useRef(null);

  const clearExistingTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const openSnackBar = (component) => {
    setComponent(component);
    clearExistingTimer();
    setIsActive(true);
    timerRef.current = setTimeout(() => {
      setIsActive(false);
    }, 3000);
  };

  const closeSnackBar = () => {
    setComponent(null);
    setIsActive(false);
    clearExistingTimer();
  };

  useEffect(() => {
    return () => {
      clearExistingTimer();
      setComponent(null);
    };
  }, []);

  return { isActive, openSnackBar, closeSnackBar, snackChildren: component };
}

function useSnackbar() {
  const _renderSnack = (mess, icon) => {
    return (
      <Alert
        elevation={6}
        variant="filled"
        color="white"
        iconMapping={{
          success: icon,
        }}
        onClose={globalThis.closeSnackBar()}
        severity="success"
        sx={{ width: "100%", color: theme.palette.text.primary }}
      >
        {mess}
      </Alert>
    );
  };

  const openSnackBar = (mess = "", icon, type = "success") => {
    if (!type) return;
    if (type === "success") {
      globalThis.openSnackBar(() => _renderSnack(mess, icon));
    }
  };

  return { openSnackBar };
}

export { useSnackbarBase, useSnackbar };
