import { useMediaQuery } from "@mui/material";
import { useMemo } from "react";

export const useBreakpoint = () => {
  const xsUp = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const smUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const breakpoint = useMemo(() => {
    switch (true) {
      case xlUp:
        return "xl";
      case lgUp:
        return "lg";
      case mdUp:
        return "md";
      case smUp:
        return "sm";
      case xsUp:
        return "xs";
      default:
        return "xxs";
    }
  }, [xsUp, smUp, mdUp, lgUp, xlUp]);

  return { breakpoint, xsUp, smUp, mdUp, lgUp, xlUp };
};
