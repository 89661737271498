import { useEffect } from "react";
import { useStoreActions } from "easy-peasy";
import dynamic from "next/dynamic";
import { Box, Snackbar } from "@mui/material";

import Header from "../Header";
import Footer from "../Footer";
import { useSnackbarBase } from "hooks/useSnackbar";
import { useBreakpoint } from "hooks/useBreakpoint";
import CookieInfo from "../CookieInfo";

const BreakpointShow = dynamic(() => import("./BreakpointShow"));

const Layout = ({
  children,
  variant = "primary",
  dictionaries = null,
  disableMobileFooter = false,
  showMoreSpace = false,
}) => {
  const setDictionary = useStoreActions(
    (actions) => actions.dictionary.setDictionary
  );

  const { isActive, openSnackBar, closeSnackBar, snackChildren } =
    useSnackbarBase();

  const { xsUp, breakpoint } = useBreakpoint();

  useEffect(() => {
    if (dictionaries) {
      setDictionary(dictionaries);
    }
  }, [dictionaries]);

  // globalThis polyfill
  (function () {
    if (typeof globalThis === "object") return;
    Object.defineProperty(Object.prototype, "__magic__", {
      get: function () {
        return this;
      },
      configurable: true,
    });
    __magic__.globalThis = __magic__;
    delete Object.prototype.__magic__;
  })();

  globalThis.openSnackBar = openSnackBar;
  globalThis.closeSnackBar = closeSnackBar;

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Header variant={variant} />
      <Box flexGrow={1} display="flex" flexDirection="column">
        {children}
      </Box>
      {!disableMobileFooter || breakpoint !== "xs" ? <Footer /> : null}
      {showMoreSpace && breakpoint === "xs" ? <Box height={10} /> : null}
      {true && process.env.NODE_ENV === "development" ? (
        <BreakpointShow />
      ) : null}
      <Snackbar
        open={isActive}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {snackChildren}
      </Snackbar>
      <CookieInfo />
    </Box>
  );
};

export default Layout;
