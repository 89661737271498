import { useEffect, useMemo, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import { Box, Container, IconButton, Typography } from "@mui/material";
import { Favorite, Menu } from "@mui/icons-material";

import FavoritesModal from "modals/FavoritesModal";
import LanguageSwitcher from "./components/LanguageSwitcher";
import MobileMenu from "./components/MobileMenu";
import NavLink from "./components/NavLink";

import DommiBlackLogo from "assets/logos/dommi_black.png";
import DommiWhiteLogo from "assets/logos/dommi_white.png";

import useScrollPosition from "hooks/useScrollPosition";
import theme from "theme";
import useFavorites from "hooks/useFavorites";

const Header = ({ variant = "primary" }) => {
  const [enableTransition, setEnableTransition] = useState(false);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [openNavMenu, setOpenNavMenu] = useState(false);
  const { countFavorites } = useFavorites();

  const { t } = useTranslation("navigation");

  const { overTenPx } = useScrollPosition();

  useEffect(() => {
    setTimeout(() => {
      setEnableTransition(true);
    }, 100);

    return () => {
      setEnableTransition(false);
    };
  }, []);

  const getStyle = useMemo(() => {
    let color = theme.palette.text.secondary;
    let logo = DommiWhiteLogo;
    let backgroundColor = "transparent";
    let colorIcon = "secondary";

    switch (variant) {
      case "primary":
        color = theme.palette.text.secondary;
        logo = DommiWhiteLogo;
        backgroundColor = overTenPx
          ? theme.palette.primary.main
          : "transparent";
        colorIcon = "secondary";
        break;

      case "secondary":
        color = theme.palette.text.primary;
        logo = DommiBlackLogo;
        backgroundColor = theme.palette.white;
        colorIcon = "primary";
        break;

      default:
        color = theme.palette.text.secondary;
        logo = DommiWhiteLogo;
        backgroundColor = overTenPx
          ? theme.palette.primary.main
          : "transparent";
        colorIcon = "secondary";
        break;
    }

    return {
      color,
      logo,
      backgroundColor,
      colorIcon,
    };
  }, [variant, overTenPx]);

  const navigationItems = useMemo(() => {
    return [
      {
        name: t("Home"),
        href: "/",
      },
      {
        name: t("Find an apartment"),
        href: `/${t("navigationPaths.search")}`,
      },
      {
        name: t("About"),
        href: `/${t("navigationPaths.about")}`,
      },
      // {
      //   name: t("Blog"),
      //   href: `/${t("navigationPaths.blog")}`,
      // },
      {
        name: t("Contact"),
        href: `/${t("navigationPaths.contact")}`,
      },
    ];
  }, [t]);

  return (
    <header>
      <Box
        position="fixed"
        // top={overTenPx ? -50 : 0}
        top={0}
        left={0}
        zIndex={2}
        width="100%"
        // sx={{
        //   transition: enableTransition ? "all 0.2s ease-in-out" : "none",
        // }}
      >
        <Box
          maxWidth="full"
          height={50}
          display="flex"
          alignItems="center"
          backgroundColor={getStyle.backgroundColor}
          sx={{
            transition: "all 0.2s ease-in-out",
          }}
        >
          {/* LAST: "xl" : "lg" */}
          <Container maxWidth={false} fixed>
            <Box
              maxWidth="xl"
              width="100%"
              margin="0 auto"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              paddingLeft={{ xs: 4, lg: 0 }}
              paddingRight={{ xs: 4, lg: 0 }}
            >
              <Box display="flex" columnGap={6} alignItems="center">
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      md: "block",
                    },
                  }}
                >
                  <LanguageSwitcher color={getStyle.color} />
                </Box>
                <NavLink
                  href="mailto:kontakt@dommi.pl"
                  fontSize="0.8rem"
                  fontWeigth={400}
                  line={false}
                  textTransform="none"
                  color={getStyle.color}
                >
                  kontakt@dommi.pl
                </NavLink>
                <NavLink
                  href="tel:++48668277589"
                  fontSize="0.8rem"
                  fontWeigth={400}
                  line={false}
                  textTransform="none"
                  color={getStyle.color}
                >
                  +48 668 277 589
                </NavLink>

                <IconButton
                  onClick={() => setShowFavoritesModal(true)}
                  aria-label="show favorites"
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                  position="relative"
                >
                  <Favorite fontSize="large" color="secondary">
                    2
                  </Favorite>
                  <Box
                    position="absolute"
                    top={"50%"}
                    left={"50%"}
                    sx={{
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Typography
                      fontSize="0.8rem"
                      lineHeight="1rem"
                      color="white"
                      fontWeight={600}
                      suppressHydrationWarning // nextjs Text content does not match server-rendered HTML
                    >
                      {countFavorites
                        ? countFavorites > 9
                          ? "9+"
                          : countFavorites
                        : ""}
                    </Typography>
                  </Box>
                </IconButton>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box
        position="fixed"
        // top={overTenPx ? 0 : 50}
        top={50}
        left={0}
        zIndex={2}
        width="100%"
        backgroundColor={
          // scrollPosition > 10 ? "rgba(0,0,0,0.8)" : "transparent"
          getStyle.backgroundColor
        }
        boxShadow={
          variant === "secondary"
            ? "0px 17px 18px -22px rgba(66, 68, 90, 0.4)"
            : null
        }
        sx={{
          transition: "background-color 0.2s ease-in-out",
        }}
      >
        {/* LAST: "xl" : "lg" */}
        <Container maxWidth={false} fixed>
          <Box
            maxWidth="xl"
            margin={{
              md: "20px auto",
              xs: "10px auto",
            }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft={{ xs: 4, lg: 0 }}
            paddingRight={{ xs: 4, lg: 0 }}
          >
            <Link href={"/"} passHref>
              <Image
                src={getStyle.logo}
                alt="logo dommi"
                height={30}
                style={{ cursor: "pointer" }}
              />
            </Link>
            <Box
              sx={{
                display: {
                  md: "flex",
                  xs: "none",
                },
              }}
            >
              <Box display="flex" columnGap={8}>
                {navigationItems.map((item, index) => (
                  <NavLink
                    key={index}
                    color={getStyle.color}
                    href={item.href}
                    line={true}
                    textTransform="uppercase"
                  >
                    {item.name}
                  </NavLink>
                ))}
              </Box>
            </Box>
            <IconButton
              aria-label="menu"
              sx={{
                display: {
                  md: "none",
                },
              }}
              onClick={() => setOpenNavMenu(true)}
            >
              <Menu
                fontSize="large"
                sx={{
                  fill: getStyle.color,
                }}
              />
            </IconButton>
          </Box>
        </Container>
      </Box>
      <FavoritesModal
        open={showFavoritesModal}
        handleClose={() => setShowFavoritesModal(false)}
      />
      <MobileMenu
        navigations={navigationItems}
        open={openNavMenu}
        onClose={() => setOpenNavMenu(false)}
        color={getStyle.color}
        openFavoritesModal={() => setShowFavoritesModal(true)}
        countFavorites={countFavorites}
      />
    </header>
  );
};

export default Header;
