import Head from "next/head";

const Seo = ({ title, description = "DOMMI" }) => {
  const pageTitle = (name) => (name ? `${name} - DOMMI` : `DOMMI`);
  return (
    <Head>
      <title>{pageTitle(title)}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={pageTitle(title)} />
      <meta name="og:description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="apple-mobile-web-app-title" content={pageTitle(title)} />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <link rel="manifest" href="/manifest.json" />

      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
};

export default Seo;
