import axios from "axios";
import * as Sentry from "@sentry/nextjs";

const instance = axios.create({
  baseURL: process.env.BASE_URL,
  withCredentials: true,
});

instance.defaults.headers.common["Content-Type"] = `application/json`;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      Sentry.captureException(error.response.data);
    } else if (error.request) {
      console.log(error.request);
    } else {
      Sentry.captureException(error.message);
    }
    Sentry.captureException(error);

    return Promise.reject(error);
  }
);

export default instance;
