import { i18n } from "next-i18next";

export function isArray(v) {
  return v instanceof Array;
}
export function isObject(v) {
  if (!v) return false;
  let r = false;
  if (v instanceof Object) r = true;
  if (isArray(v)) r = false;
  return r;
}

export function isString(v) {
  return typeof v === "string" || v instanceof String;
}
export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
export function convertPrice(price, showDecimals = false) {
  let decimals = showDecimals ? 2 : 0;

  let priceNumber = parseFloat(price).toFixed(decimals);

  let priceArray = priceNumber.split(".");
  priceArray[0] = priceArray[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return priceArray.join(".");
}

export const convertNameOfCountRooms = (rooms) => {
  const t = i18n.t;

  if (rooms === 1) {
    return t("room");
  } else if (rooms > 1 && rooms < 5) {
    return t("rooms.few");
  } else {
    return t("rooms.many");
  }
};
export const convertNameOfCountFloor = (floor) => {
  if (!floor) return null;
  const t = i18n.t;

  if (floor === 1) {
    return t("floor.one");
  } else {
    return `${floor} ${t("floor.many")}`;
  }
};

export const mapToSelect = (data) => {
  return data.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
