import {
  Crop,
  DashboardOutlined,
  PaidOutlined,
  UnfoldMore,
  ViewColumnOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Button from "components/Atoms/Button";
import BoxInformation from "components/Modules/OfferCard/components/BoxInformation";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import theme from "theme";
import {
  convertNameOfCountFloor,
  convertNameOfCountRooms,
  convertPrice,
} from "utils/functions";
import useDynamicLink from "hooks/useDynamicLink";

const Item = ({
  uuid,
  price,
  area,
  rooms,
  floor,
  balcony,
  city_name,
  quarter_name,
  street_name,
  handleRemove,
  name,
  files,
  closeModal = () => {},
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { t: tNavigation } = useTranslation("navigation");
  const { generateLink } = useDynamicLink();

  const getCityAndQuarter = useMemo(() => {
    if (!city_name && !quarter_name && !street_name && !name) return null;
    const arr = [
      city_name,
      quarter_name,
      street_name ? `ul. ${street_name}` : null,
      name,
    ];
    return arr.filter((item) => item).join(", ");
  }, [city_name, quarter_name]);

  const handleClick = (e) => {
    closeModal();

    const lintGen = generateLink({
      elements: [city_name, quarter_name],
      uuid: uuid,
    });

    router.push(
      `/${tNavigation("navigationPaths.search")}/${
        lintGen?.valid ? lintGen.link : el?.uuid
      }`
    );
  };
  return (
    <Box
      display="flex"
      p={2}
      columnGap={4}
      flexDirection={{ xs: "column", md: "row" }}
      borderBottom={`1px solid ${theme.palette.background.default}`}
      sx={{
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: theme.palette.background.default,
        },
      }}
      onClick={handleClick}
    >
      <Box
        width={{ xs: "100%", md: 120 }}
        height={{ xs: 200, md: 80 }}
        position="relative"
        borderRadius={2}
        overflow="hidden"
      >
        <Image
          alt="Property image"
          src={
            files?.[0]?.file
              ? `${process.env.BASE_PLAN_URL}${files?.[0]?.file}`
              : null
          }
          fill={true}
          sizes="100%"
          style={{
            objectFit: "cover",
          }}
        />
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent={{ xs: "space-between", md: "space-between" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        flexDirection={{ xs: "column", md: "row" }}
        rowGap={2}
      >
        <Box display="flex" flexDirection="column" height="100%" rowGap={2}>
          <Typography>{getCityAndQuarter}</Typography>
          <Box display="flex" alignItems="center" gap={2} flexWrap="wrap">
            <BoxInformation icon={<PaidOutlined />}>
              {convertPrice(price)} PLN
            </BoxInformation>
            <BoxInformation icon={<Crop />}>
              {area} m<sup>2</sup>
            </BoxInformation>
            <BoxInformation icon={<DashboardOutlined />}>
              {rooms} {convertNameOfCountRooms(rooms)}
            </BoxInformation>
            <BoxInformation icon={<UnfoldMore />}>
              {convertNameOfCountFloor(floor)}
            </BoxInformation>
            {balcony === "1" ? (
              <BoxInformation icon={<ViewColumnOutlined />}>
                {t("balcony")}
              </BoxInformation>
            ) : null}
          </Box>
        </Box>
        <Box display="flex" height="100%" alignItems="flex-end" pb={1}>
          <Button
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();

              handleRemove(uuid);
            }}
            sx={{ py: 0, height: 40 }}
          >
            {t("Remove from favorites")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Item;
