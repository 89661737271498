import { useEffect, useState } from "react";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [overTenPx, setOverTenPx] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 10) {
      setOverTenPx(true);
    } else {
      setOverTenPx(false);
    }

    setScrollPosition(position);
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      handleScroll();

      window.addEventListener("scroll", handleScroll, { passive: true });

      return () => {
        window.removeEventListener("scroll", handleScroll);
        setScrollPosition(0);
        setOverTenPx(false);
      };
    }
  }, []);

  return scrollPosition, { overTenPx };
};

export default useScrollPosition;
