import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Menu, MenuItem, Typography } from "@mui/material";

import { useRouter } from "next/router";
import { useMemo, useRef, useState } from "react";

const LanguageSwitcher = ({ color, propsText, propsIcon }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const router = useRouter();

  const buttonRef = useRef(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleClickItem = (e) => {
    handleClose();
    const v = e?.currentTarget?.dataset?.value;
    router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      null,
      { locale: v, scroll: false }
    );
  };

  const getLabel = useMemo(() => {
    const locale = router.locale;
    switch (locale) {
      case "pl":
        return "PL";
      case "en":
        return "EN";
      default:
        return "PL";
    }
  }, [router.locale]);

  return (
    <Box>
      <Box
        ref={buttonRef}
        onClick={handleClick}
        display="flex"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        sx={{
          color: color,
          cursor: "pointer",
          transition: "all 0.3s ease",
          ":hover": {
            opacity: 0.7,
          },
        }}
      >
        <Typography fontSize="0.8rem" component="span" {...propsText}>
          {getLabel}
        </Typography>
        <KeyboardArrowDown fontSize="small" {...propsIcon} />
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
      >
        <MenuItem
          onClick={handleClickItem}
          data-value="pl"
          sx={{
            fontSize: "0.8rem",
          }}
        >
          PL
        </MenuItem>
        <MenuItem
          onClick={handleClickItem}
          data-value="en"
          sx={{
            fontSize: "0.8rem",
          }}
        >
          EN
        </MenuItem>
      </Menu>
    </Box>

    // <div>
    //   <select
    //     onChange={(e) =>
    //       router.push(
    //         {
    //           pathname: router.pathname,
    //           query: router.query,
    //         },
    //         null,
    //         { locale: e.target.value }
    //       )
    //     }
    //   >
    //     <option value="pl">Polski</option>
    //     <option value="en">English</option>
    //   </select>
    // </div>
  );
};

export default LanguageSwitcher;
