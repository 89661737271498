import { useEffect, useState } from "react";
import useIsomorphicLayoutEffect from "./useIsoMorphicLayoutEffect";

export function useLockedScroll(initialLocked = true, rootId = "__next") {
  const [locked, setLocked] = useState(initialLocked);

  useIsomorphicLayoutEffect(() => {
    if (!locked) {
      return;
    }

    document.body.classList.add("disableScroll");
    return () => {
      document.body.classList.remove("disableScroll");
    };
  }, [locked]);

  useEffect(() => {
    if (locked !== initialLocked) {
      setLocked(initialLocked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLocked]);

  return [locked, setLocked];
}

export default useLockedScroll;
