import { Box, Typography, Link } from "@mui/material";

import { useRouter } from "next/router";
import theme from "theme";

const NavLink = ({
  children,
  href = "/",
  fontSize = "1rem",
  fontWeigth = 600,
  textTransform = "uppercase",
  line = true,
  color = theme.palette.text.secondary,
  sx = {},
  ...rest
}) => {
  const { pathname } = useRouter();

  return (
    <Link href={href} passHref sx={sx} {...rest}>
      <Typography
        color={color}
        textTransform={textTransform}
        fontWeight={fontWeigth}
        fontSize={fontSize}
        sx={{
          transition: "all 0.2s ease-in-out",
          maxWidth: "max-content",
          display: "inline-block",
          "&:hover": {
            opacity: 0.7,
          },
        }}
      >
        {children}
      </Typography>
      {line ? (
        <Box
          width={pathname === href ? "30%" : "0%"}
          height={3}
          backgroundColor={color}
          margin={"0 auto"}
        />
      ) : null}
    </Link>
  );
};
export default NavLink;
