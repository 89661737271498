import {
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import { Close, Favorite } from "@mui/icons-material";

import NavLink from "../NavLink";

import DommiBlackLogo from "assets/logos/dommi_black.png";
import Image from "next/image";
import theme from "theme";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "next-i18next";

const MobileMenu = ({
  open = true,
  onClose = () => {},
  navigations,
  openFavoritesModal,
  countFavorites,
}) => {
  const { t } = useTranslation();
  return (
    <Drawer anchor="top" open={open} onClose={onClose}>
      <Container maxWidth={false} fixed>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pl={4}
          pr={2}
        >
          <LanguageSwitcher
            propsText={{
              color: "black",
              fontWeight: 600,
              fontSize: "1.3rem",
            }}
            propsIcon={{
              fontSize: "medium",
            }}
          />
          <IconButton aria-label="menu" onClick={onClose}>
            <Close
              fontSize="large"
              sx={{
                fill: "black",
              }}
            />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems={"center"}
          gap={4}
          pb={4}
        >
          <Image
            src={DommiBlackLogo}
            alt="logo dommi"
            height={30}
            style={{ cursor: "pointer", marginBottom: 10 }}
          />
          {navigations.map((item, index) => (
            <NavLink
              key={index}
              color={"black"}
              href={item.href}
              line={true}
              textTransform="none"
              fontSize="1.3rem"
              sx={{
                maxWidth: "max-content",
              }}
              onClick={onClose}
            >
              {item.name}
            </NavLink>
          ))}
          <Divider
            sx={{
              width: "100%",
            }}
          />
          <Box
            onClick={() => {
              openFavoritesModal();
              onClose();
            }}
            display="flex"
            alignItems={"center"}
            sx={{
              cursor: "pointer",
            }}
          >
            <Typography
              color={"black"}
              fontWeight={600}
              fontSize="1.3rem"
              sx={{
                transition: "all 0.2s ease-in-out",
                maxWidth: "max-content",
                display: "inline-block",
                "&:hover": {
                  opacity: 0.7,
                },
              }}
            >
              {t("Favorites")}
            </Typography>
            <IconButton
              onClick={() => openFavoritesModal()}
              aria-label="show favorites"
              position="relative"
            >
              <Favorite fontSize="large" color="secondary">
                2
              </Favorite>
              <Box
                position="absolute"
                top={"50%"}
                left={"50%"}
                sx={{
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography
                  fontSize="0.8rem"
                  lineHeight="1rem"
                  color="white"
                  fontWeight={600}
                  suppressHydrationWarning // nextjs Text content does not match server-rendered HTML
                >
                  {countFavorites
                    ? countFavorites > 9
                      ? "9+"
                      : countFavorites
                    : ""}
                </Typography>
              </Box>
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Drawer>
  );
};

export default MobileMenu;
