import { Box, Typography } from "@mui/material";
import { Description } from "@mui/icons-material";

import theme from "theme";

const BoxInformation = ({ children, icon, sxText }) => {
  const displayIcon = icon || <Description />;

  return (
    <Box
      backgroundColor={theme.palette.background.default}
      height={40}
      px={2}
      display="flex"
      alignItems={"center"}
      borderRadius={2}
      boxShadow="0 0 2px 1px #dfedf2"
      gap={1}
    >
      {displayIcon}
      <Typography fontSize="0.8rem" fontWeight={500} sx={{ ...sxText }}>
        {children}
      </Typography>
    </Box>
  );
};

export default BoxInformation;
