import Image from "next/image";
import { useTranslation } from "next-i18next";
import { Box, Container, Link, Typography } from "@mui/material";
import theme from "theme";

import DommiWhiteLogo from "assets/logos/dommi_white.png";
import useWindowSize from "hooks/useWindowSize";
import { useMemo } from "react";

const fontSizesText = {
  lg: 15,
  md: 13,
  xs: 12,
};

const Footer = () => {
  const { height } = useWindowSize();

  const footerHeight = useMemo(() => {
    let h = 130;

    if (height < 800) {
      h = 80;
    }

    return h;
  }, [height]);

  const { t } = useTranslation("navigation");
  return (
    <Box width="100%" backgroundColor={theme.palette.primary.main}>
      <Container maxWidth={false} fixed>
        <Box
          height={footerHeight}
          margin="0px auto"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          alignItems="center"
          justifyContent={{ xs: "center", lg: "space-between" }}
          rowGap={4}
          paddingLeft={{ xs: 4, lg: 0 }}
          paddingRight={{ xs: 4, lg: 0 }}
          py={{
            xs: 16,
            lg: 0,
          }}
        >
          <Box display="flex" alignItems="center" columnGap={4}>
            <Image src={DommiWhiteLogo} alt="logo dommi" height={30} />
            <Typography fontSize={fontSizesText} color="white">
              All rights reserved © 2023
            </Typography>
          </Box>
          <Link
            fontSize={fontSizesText}
            href="https://voxdeveloper.com/"
            target="_blank"
            color="white"
          >
            {t("Implementation: VoxCRM")}
          </Link>
          <Box
            display="flex"
            columnGap={4}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography fontSize={fontSizesText} color="white">
              {t("Privacy policy")}
            </Typography>
            <Typography fontSize={fontSizesText} color="white">
              /
            </Typography>
            <Typography fontSize={fontSizesText} color="white">
              {t("Cookies policy")}
            </Typography>
            <Typography fontSize={fontSizesText} color="white">
              /
            </Typography>
            <Link fontSize={fontSizesText} href="/contact" color="white">
              {t("Contact")}
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
