import { useStoreActions, useStoreState } from "easy-peasy";

const useFavorites = () => {
  const favorites = useStoreState((state) => state.favorites.favorites);
  const addFavorite = useStoreActions(
    (actions) => actions.favorites.addFavorite
  );
  const removeFavorite = useStoreActions(
    (actions) => actions.favorites.removeFavorite
  );

  const addToFavorites = async (uuid) => {
    try {
      if (favorites.includes(uuid))
        return console.log({
          success: false,
          message: "Already in favorites",
        });
      await addFavorite(uuid);

      return { success: true };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  };

  const removeFromFavorites = async (uuid) => {
    try {
      await removeFavorite(uuid);

      return { success: true, data: favorites };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  };

  const getAllFavorites = favorites;

  return {
    addToFavorites,
    removeFromFavorites,

    getAllFavorites,
    countFavorites: favorites.length,
  };
};

export default useFavorites;
