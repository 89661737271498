import { isString } from "utils/functions";

const useDynamicLink = () => {
  const isValidUUID = (uuid) => {
    const regex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return regex.test(uuid);
  };

  const parseLink = (link) => {
    const parts = link.split("_");
    const uuid = parts.pop();

    return { elements: parts, uuid, valid: isValidUUID(uuid) };
  };

  const generateLink = ({ elements = [], uuid }) => {
    const filterElements = elements.filter((el) => el && isString(el));

    return {
      valid: isValidUUID(uuid),
      link: [...filterElements, uuid].join("_"),
    };
  };

  return { parseLink, generateLink };
};

export default useDynamicLink;
