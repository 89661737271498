import axios from "../utils/axios";

const PropertiesService = {
  getProperties: async (params) => {
    return await axios.get("/properties", {
      params: {
        limit: 8,
        ...params,
      },
    });
  },
  getProperty: async (uuid, params) => {
    return await axios.get(`/properties/${uuid}`, {
      params: {
        ...params,
      },
    });
  },
  downloadFile: async (uuid, nameFile = null) => {
    return await axios
      .get(`properties/files/${uuid}/download`, { responseType: "blob" })
      .then((res) => {
        console.log({ res });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", nameFile || `card.pdf`);
        document.body.appendChild(link);
        link.click();

        return { success: true };
      });
  },
  getFile: async (uuid) => {
    return await axios.get(`properties/files/${uuid}`);
  },
};

export default PropertiesService;
